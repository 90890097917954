import { User } from "./user";

export enum SocialLoginTypes {
  google = "google",
  twitter = "twitter",
}

export enum AuthActionTypes {
  signup = "signup",
  login = "login",
  forgotPassword = "forgotPassword",
  newPassword = "newPassword",
}

export interface ModalState {
  isModalVisible: boolean;
  isFeedbackModal?: boolean;
  isPlaylistSubmissionModal?: boolean;
  redirectUrl?: string;
  defaultActionType?: AuthActionTypes;
}

export interface Authentication {
  user: User;
  authToken: string;
}

export interface Signup {
  firstName: string;
  email: string;
  password: string;
}

export interface SignUpError {
  fullNameError: string;
  emailError: string;
  passwordError: string;
}

export interface ApplyProError {
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  instagramError: string;
  twitterError: string;
  worksAtError: string;
}

export interface ForgotPasswordError {
  emailError: string;
}

export interface NewPasswordError {
  passwordError: string;
}

export interface PasswordChanged {
  passwordSet: boolean;
}

export interface ProCandidateRegister {
  email: string;
  phone?: string;
  firstName: string;
  lastName: string;
  socialLinks: string[];
  expertise?: string;
  worksAt?: string;
  about?: string;
  referral?: string;
}

export interface ProCandidate extends ProCandidateRegister {
  _id: string;
  status: string;
}
